import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import FormLogo from '../elements/FormLogo';
import ENDPOINT from '../../constants/api-endpoints';
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';


const MyOrder = () => {
  const [myOrderList, setMyOrderList] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
  
    axios
      .get(ENDPOINT.MY_ORDER_LIST, {})
      .then((response) => {
        setMyOrderList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const encryptId = (id) => {
    // Encrypt the ID using hashing (MD5 in this case)
    return CryptoJS.MD5(id).toString();
  };


  return (
    <>
      <section className="full_width common_heading">
        <div className="container">
          <div className="odr_frm">
              <FormLogo title="My Order" />  
              <div className="of_bx_alfa">
                <div className="row">
                  <div  className="col mb-12">
                    <table  className="table table-bordered"  >
                    <thead>
                      <tr>
                          <th>Name Of Recipient</th>
                          {/* <th>Invoice Number</th> */}
                          {/* <th>Transaction Id</th> */}
                          <th>Purchase Date</th>
                          <th>Delivery Date</th>
                          <th>Deliver Format</th>
                          <th>Total Price</th>
                          <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                        {myOrderList.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="align-middle" >No record found</td>
                          </tr>
                        ) : (
                        myOrderList.map((data, index) => (
                          <tr key={ index } >
                            <td>{ data.name_recipient }</td>
                            {/* <td>{ data.invoice_number }</td> */}
                            {/* <td>{ data.transaction_id }</td> */}
                            <td>{ data.purchase_date }</td>
                            <td>{ data.delivery_date }</td>
                            {data.deliver_format === 'online_viewing_link' ? (
                            <td className="">
                                {data.online_viewing_link ? (
                                  <a href={ data.online_viewing_link } target="_blank">{ data.deliver_format.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</a>
                                ) : (
                                    "Pending"
                                )}
                                
                            </td>
                            ) : ( 
                              <td className="">
                                  {data.digital_download_url ? (
                                    <a href={ data.digital_download_url } download target="_blank">{ data.deliver_format.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</a>
                                  ) : (
                                      "Pending"
                                  )}
                                  
                              </td>
                            )}
                            <td>{ data.total_price }</td>
                            <Link to={`/order-detail/${btoa(data.id)}`}>
                              <td>View Detail</td>
                            </Link>
                          </tr>
                          ))
                        )}
                      </tbody>
                    </table>

                  
                  </div>
                 
                </div>
                
              </div>
          </div>
        </div>
      </section>



     
    </>
  );
};

export default MyOrder;
