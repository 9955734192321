import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import ENDPOINT from "../../constants/api-endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Link } from "react-router-dom";




// Define Yup validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  password: Yup.string().required("Password is required"),
  // address1: Yup.string().required("Address 1 is required"),
  // city: Yup.string().required("City is required"),
  // state: Yup.string().required("State is required"),
  // zipcode: Yup.string().required("zipcode is required"),
});

// Initial form values
const initialValues = {
  name: "",
  email: "",
  phone: "",
  password: "",
  // address1: "",
  // address2: "",
  // city: "",
  // state: "",
  // zipcode: "",
};

// Form component
const Register = () => {
  const navigate = useNavigate();
  const userLoginOrNot = localStorage.getItem("BearerToken");
  useEffect(() => {
    if (userLoginOrNot !==  null) {
        navigate('/');
    }

}, []);

  // Handle form submission
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Assuming your API endpoint is "/api/submitForm"
      console.log('values',values);
      const response = await axios.post(ENDPOINT.REGISTER, values);
      if (response.data.status) {
        toast(response.data.message);
        resetForm({});
      } else {
        toast(response.data.message);
      }
      // Handle the successful response
      // console.log("Server Response:", response.data);
    } catch (error) {
      // Handle errors
      // console.error("Submission Error:", error);
      // Reset form after an error if needed
      setSubmitting(false);
    }
  };
  return (
    <>
      <section className="full_width common_heading">
        <ToastContainer />
        <div className="container">
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
          <div className="odr_frm">
            <FormLogo title="Register" /> 
            <Form>
              <div className="of_bx_alfa loginFrm">
                <h4 className="mb-4 text-center">
                  Already have an account? <Link to="/login">Login</Link> now!{" "}
                </h4>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-flex mb-4 align-items-center justify-content-center">
                      <label for="name">Name:</label>
                      <Field type="text" id="name"  name="name" className="" placeholder="" />
                      <ErrorMessage name="name" component="div" />
                    </div>

                    <div className="d-flex mb-4 align-items-center justify-content-center">
                      <label for="email">Email:</label>
                      <Field type="text" id="email" name="email" className="" placeholder="" />
                      <ErrorMessage name="email" component="div" />
                    </div>

                    <div className="d-flex mb-4 align-items-center justify-content-center">
                      <label for="phone">Phone:</label> 
                      <Field type="text" id="phone" name="phone" className="" placeholder="" />
                      <ErrorMessage name="phone" component="div" />
                    </div>

                    <div className="d-flex mb-4 align-items-center justify-content-center">
                      <label for="password">Password:</label> 
                      <Field type="password" id="password"  name="password" className="" placeholder="" />
                      <ErrorMessage name="password" component="div" />
                    </div>

                    <div className="d-flex mb-4 align-items-center justify-content-center">
                      <input type="submit" defaultValue="Register" value="Register" />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Register;
