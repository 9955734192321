import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { P_CLIENT_ID } from '../../Config/Config';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";

import ENDPOINT from "../../constants/api-endpoints";

import {loadStripe} from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import InjectedCheckoutForm from './InjectedCheckoutForm';
import CheckoutForm from './CheckoutForm';

const OrderSummery = () => {
  const navigate = useNavigate();
  const invoiceNumber = localStorage.getItem("invoice_number");
  const localStorageData = JSON.parse(localStorage.getItem("billing_info"));
  const customOrderId = localStorage.getItem("order_id");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [transactionId, setTransactionId] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState();


  useEffect(() => {
    if (customOrderId == null) {
        navigate('/');
    }
  }, []);

  // creates a paypal order
  const createOrder = (data, actions) => {
      return actions.order.create({
          purchase_units: [
              {
                  custom_id: customOrderId,// Add your custom ID here
                  description:  "puppet",
                  amount: {
                      currency_code: "USD",
                      value: localStorageData.totalPrice,
                  },
              },
          ],
      }).then((transactionId) => {
        setTransactionId(transactionId);
        return transactionId;
      });
  };

  // check Approval
  const onApprove = (data, actions) => {
     
      return actions.order.capture().then(function (details) {
          const { payer } = details;
          navigate('/payment-success', { state: { txnId: details.id } });
          // setSuccess(true);
      });
  };

  // capture likely error
  const onError = (data, actions) => {
      navigate('/payment-fail', { state: { message: "An Error occured with your payment " } });
      // setErrorMessage("An Error occured with your payment ");
  };

  // ====================

  useEffect(() => {
    // alert(paymentIntent);
    axios.get(ENDPOINT.STRIPE_KEY + "/" + customOrderId, {})
    .then((response) => {
      console.log(response.data.paymentIntent);
       // Handle data
       setPaymentIntent(response.data.paymentIntent);
       alert(paymentIntent);
    })
    .catch((error) => {
      // console.log(error);
    })
  }, []);
const stripePromise = loadStripe('pk_test_51OhgVzLgXYNvq3r9tjtKCmkBiJoHqVMZUCIMOZt5n0WeQ33EB37M1IXbPWaDxgyDT3nfpShj5oNtpd9EpntcT1Km005H7lcMQj');


const options1 = {
  // passing the client secret obtained from the server
  clientSecret: paymentIntent,
};

// const options1 = {
//   mode: 'payment',
//   amount: 1099,
//   currency: 'usd',
//   clientSecret: paymentIntent,
//   // Fully customizable with appearance API.
//   appearance: {
//     /*...*/
//   },
// };
  

  return (
    <>
      <section className="full_width common_heading">
      <ToastContainer />
        <div className="container">
          <div className="odr_frm">
              <FormLogo title="Order Summery" />  
              <div className="of_bx_alfa">
                <div className="container">
                  <div className="row">
                    {/* BEGIN INVOICE */}
                    <div className="col-sm-12">
                      <div className="grid invoice">
                        <div className="grid-body">
                          <div className="invoice-title">
                            <div className="row">
                              <div className="col-sm-12">
                                <h3> <label>Invoice Number:</label> <span className="small">{invoiceNumber}</span> </h3>
                              </div>
                              <div className="col-sm-6"><label>Purchase Date: </label>{ localStorageData?.purchase_date }</div>
                              <div className="col-sm-6"><label>Delivery Date: </label>{ localStorageData?.delivery_date }</div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-6">
                              <address>
                                <strong>Name: </strong> { localStorageData?.name_of_recipient }
                                <br />
                                <strong>Email: </strong> { localStorageData?.email }
                                <br />
                                <abbr title="Phone"><strong>Phone: </strong>:</abbr> { localStorageData?.phone } , { localStorageData?.cell_phone }
                              </address>
                            </div>
                            <div className="col-sm-6">
                              <address>
                                <strong>Address:</strong> { localStorageData?.address }
                                <br />
                                { localStorageData?.state } ,  { localStorageData?.city } ,  { localStorageData?.zip }
                                <br />
                              </address>
                            </div>
                          </div>

                          <div className="row">
                            { localStorageData?.special_message &&
                            <div className="col-sm-6">
                              <address>
                                <strong>Special Message:</strong> { localStorageData?.special_message }
                              </address>
                            </div>
                            }
                            { localStorageData?.additional_message &&
                            <div className="col-sm-6">
                              <address>
                                <strong>Additional Information:</strong> { localStorageData?.additional_message }
                              </address>
                            </div>
                            }
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-md-12">
                              <h3>ORDER SUMMARY</h3>
                              <table className="table table-striped">
                                <thead>
                                  <tr className="line">
                                    <td className=""><strong>Profile</strong></td>
                                    <td className=""><strong>Data</strong></td>
                                    <td className=""><strong>Background</strong></td>
                                    <td className="" style={{ textAlign: "right" }}><strong>Subtotal</strong></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  
                                  <tr>
                                    <td><img src={localStorageData?.puppet_one}  style={{ width: "100px" }}/></td>
                                    <td>Puppet + {localStorageData?.backgroundType.charAt(0).toUpperCase() + localStorageData?.backgroundType.slice(1)}</td>
                                    <td className="">
                                      {localStorageData?.backgroundType === 'video' ? (
                                       <video  width="100" height="100" >
                                          <source src={localStorageData.backgroundImageURL} type="video/mp4" />
                                          Your browser does not support the video tag.
                                        </video>
                                        ) : (
                                          <img src={localStorageData?.backgroundImageURL} style={{ width: "100px" }} />
                                        )}
                                    </td>
                                    <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN} {localStorageData?.basicPrice}</td>
                                  </tr>

                                  {localStorageData?.puppet_two && 
                                  <tr>
                                    <td><img src={localStorageData?.puppet_two}  style={{ width: "100px" }}/></td>
                                    <td>Additional Puppet</td>
                                    <td className="">&nbsp;</td>
                                    <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN} {localStorageData?.additionalPuppetPrice}</td>
                                  </tr>
                                  }



                                  {localStorageData?.deliverFormatPrice && 
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>Video Deliver Option Price</td>
                                      <td className="">{ localStorageData?.deliverFormat.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }</td>
                                      <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN} {localStorageData?.deliverFormatPrice}</td>
                                    </tr>
                                  }
                                
                                  <tr>
                                    <td colSpan={2} ></td>
                                    <td className=""><strong>Taxes( { localStorageData?.taxes } {localStorageData?.taxCalculate == 'percentage' ? '%' :''})</strong></td>
                                    <td className="" style={{ textAlign: "right" }}><strong>{ENDPOINT.DOLLER_SIGN} {localStorageData?.taxAmount}</strong></td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2}></td>
                                    <td className=""><strong>Total</strong></td>
                                    <td className="" style={{ textAlign: "right" }}><strong>{ENDPOINT.DOLLER_SIGN} { localStorageData?.totalPrice }</strong></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* END INVOICE */}
                  </div>
                </div>

                {show === false && (
                  <div className="d-flex mb-4 align-items-center justify-content-center">
                    <input type="button" className="spl_btn"  defaultValue="Order Confirm" onClick={() => setShow(true)} />
                  </div>
                )}
                
                {show ? (
                  <div className='row'>
                    <div className='col-sm-6'>
                      <PayPalScriptProvider options={{ "client-id": P_CLIENT_ID }}>
                        <PayPalButtons style={{ layout: "vertical" }} createOrder={createOrder} onApprove={onApprove} />
                      </PayPalScriptProvider>
                    </div>

                    {paymentIntent ? (
                      <div className='col-sm-6'>
                      <Elements stripe={stripePromise} options={options1}>
                        <CheckoutForm />
                      </Elements>
                      </div>
                     ) : null}

                  </div>
                 ) : null}
               
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderSummery;
