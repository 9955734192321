import React from "react";
import FormLogo from '../elements/FormLogo';
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import ENDPOINT from "../../constants/api-endpoints";
import call_us_img from "../../css/images/CallUs.jpeg";

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  // message: Yup.string().required("Message is required"),
});

// Initial form values
const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const ContactUs = () => {
    // Handle form submission
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
      try {
        // Assuming your API endpoint is "/api/submitForm"
        const response = await axios.post(ENDPOINT.CONTACT_US, values);
        if (response.data.status) {
          resetForm({});
        } else {
          console.log("fail");
        }
        console.log("out");
  
        // Handle the successful response
        console.log("Server Response:", response.data);
      } catch (error) {
        // Handle errors
        console.error("Submission Error:", error);
        // Reset form after an error if needed
        setSubmitting(false);
      }
    };
  return (
    <>
      <section className="full_width common_heading">
        <div className="container">
          <div className="odr_frm">
              <FormLogo title="Contact Us" />  
              <div className="of_bx_alfa">
                <div className="row">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                  <>
                    <div className="col-sm-6">
                      <img src={call_us_img} alt="callus" />
                    </div>
                    <div className="col-sm-6">
                      <Form>
                        <div className="d-flex mb-4">
                          <label for="name">Name:</label>
                          <Field type="text" id="name"  name="name" className="" placeholder="" />
                          <ErrorMessage className="text-danger" name="name" component="div" />
                        </div>
                        <div className="d-flex mb-4">
                          <label for="phone">Phone:</label> 
                          <Field type="text" id="phone" name="phone" className="" placeholder="" />
                          <ErrorMessage className="text-danger" name="phone" component="div" />
                        </div>
                        <div className="d-flex mb-4">
                          <label for="email">Email:</label>
                          <Field type="text" id="email" name="email" className="" placeholder="" />
                          <ErrorMessage className="text-danger" name="email" component="div" />
                        </div>
                        <div className="d-flex mb-4">
                          <label for="message">Message:</label>
                          <Field as="textarea" rows={3} cols={30} id="message"  name="message" placeholder="" /> 
                          <ErrorMessage className="text-danger" name="message" component="div" />
                        </div>
                        <div className="d-flex mb-4 align-items-center justify-content-center">
                          <input type="submit" defaultValue="Submit" />
                        </div>
                      </Form>
                    </div>
                  </>
                  </Formik>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
