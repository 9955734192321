import React, { useState, useRef, useEffect } from 'react';
import { useParams , Link, useLocation} from "react-router-dom";
import axios from 'axios';
import FormLogo from '../elements/FormLogo';
import ENDPOINT from '../../constants/api-endpoints';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import { useReactToPrint } from 'react-to-print';

const OrderDetail = () => {
  let { id } = useParams();
  let decodedId = atob(id);
  const [orderDetail, setOrderDetail] = useState({});
  const [orderDetailId, setOrderDetailId] = useState();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

 
  useEffect(() => {
    axios
      .get(ENDPOINT.ORDER_DETAIL + "/" + decodedId, {})
      .then((response) => {
        console.log(response.data);
        setOrderDetailId(response.data.data.id);
        setOrderDetail(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
 
  return (
    <>
      <section className="full_width common_heading">
      <ToastContainer />
        <div className="container">
          <div className="odr_frm">
          <div className="odr_frm">
            <FormLogo title="Order Detail" /> 
              <div className="of_bx_alfa " ref={componentRef}>
                  <div className="row">
                    <div className="col mb-4">
                      <div className="d-flex">
                        <label>Order Details</label>
                      </div>
                    </div>
                    <div className="col mb-8">
                      <div className="d-flex " style={{ float:"right"}} >
                        <button onClick={handlePrint}>Print </button>
                      </div>
                    </div>
                  </div> 
                  <div className='row'>
                    <div className="container">
                      <div className="row">
                        {/* BEGIN INVOICE */}
                        <div className="col-sm-12">
                          <div className="grid invoice">
                            <div className="grid-body">
                              <div className="invoice-title">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <h3> <label>Invoice Number:</label> <span className="small">{orderDetail?.invoice_number}</span> </h3>
                                  </div>
                                  <div className="col-sm-6"><label>Purchase Date:</label>{ orderDetail?.purchase_date }</div>
                                  <div className="col-sm-6"><label>Delivery Date:</label>{ orderDetail?.delivery_date }</div>
                                </div>
                              </div>
                              <hr />

                              <div className="row">
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Name: </strong> { orderDetail?.name_recipient }
                                    <br />
                                    <strong>Email: </strong> { orderDetail.order_record?.email }
                                    <br />
                                    <abbr title="Phone"><strong>Phone: </strong>:</abbr> { orderDetail.order_record?.phone } , { orderDetail.order_record?.cell_phone }
                                  </address>
                                </div>
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Address:</strong> { orderDetail.order_record?.address }
                                    <br />
                                    { orderDetail.order_record?.state } ,  { orderDetail.order_record?.city } ,  { orderDetail.order_record?.zip }
                                    <br />
                                  </address>
                                </div>
                              </div>
                              
                              <div className="row">
                                { orderDetail.order_record?.special_message &&
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Special Message:</strong> { orderDetail.order_record?.special_message }
                                  </address>
                                </div>
                                }
                                { orderDetail.order_record?.additional_message &&
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Additional Information:</strong> { orderDetail.order_record?.additional_message }
                                  </address>
                                </div>
                                }
                              </div>
                              <hr />

                              <div className="row">
                                <div className="col-md-12">
                                  <h3>ORDER SUMMARY</h3>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr className="line">
                                        <td className=""><strong>Profile</strong></td>
                                        <td className=""><strong>Data</strong></td>
                                        <td className=""><strong>Background</strong></td>
                                        <td className="" style={{ textAlign: "right" }}><strong>Subtotal</strong></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      
                                      <tr>
                                        <td><img src={ orderDetail.order_record?.puppet_one}  style={{ width: "100px" }}/></td>
                                        <td>Puppet + {orderDetail.order_record?.backgroundType.charAt(0).toUpperCase() + orderDetail.order_record?.backgroundType.slice(1)}</td>
                                        <td className="">
                                          {orderDetail.order_record?.backgroundType === 'video' ? (
                                          <video  width="100" height="100" >
                                            <source src={orderDetail.order_record?.backgroundImageUR} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                          ) : (
                                            <img src={ orderDetail.order_record?.backgroundImageURL} style={{ width: "100px" }}/>
                                          )}
                                          </td>
                                        <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN} { orderDetail.order_record?.basicPrice}</td>
                                      </tr>

                                      { orderDetail.order_record?.puppet_two && 
                                      <tr>
                                        <td><img src={ orderDetail.order_record?.puppet_two}  style={{ width: "100px" }}/></td>
                                        <td>Additional Puppet</td>
                                        <td className="">&nbsp;</td>
                                        <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN} { orderDetail.order_record?.additionalPuppetPrice}</td>
                                      </tr>
                                      }

                                      { orderDetail.order_record?.puppet_two && 
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>Video Deliver Option Price</td>
                                        <td className="">&nbsp;</td>
                                        <td  className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN} { orderDetail.order_record?.deliverFormatPrice}</td>
                                      </tr>
                                      }
                                    
                                      <tr>
                                        <td colSpan={2} />
                                        <td className=""><strong>Taxes ( { orderDetail.order_record?.taxes } {orderDetail.order_record?.taxCalculate == 'percentage' ? '%' :''})  </strong></td>
                                        <td className="" style={{ textAlign: "right" }}><strong>&nbsp;&nbsp;&nbsp;{ENDPOINT.DOLLER_SIGN} { orderDetail.order_record?.taxAmount } </strong></td>
                                      </tr>
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td className=""><strong>Total</strong></td>
                                        <td className="" style={{ textAlign: "right" }}><strong>{ENDPOINT.DOLLER_SIGN} {  orderDetail.order_record?.totalPrice }</strong></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <hr/>

                              <div className="row">
                                <div className="col-md-12">
                                  <h3>PAYMENT INFORMATION</h3>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr className="line">
                                        <td className=""><strong>Transaction ID</strong></td>
                                        <td className=""><strong>Payment Get Way</strong></td>
                                        <td className=""><strong>Payment Status</strong></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="">{orderDetail.transaction_id ? orderDetail.transaction_id  : 'NULL'}</td>
                                        <td className="">{orderDetail.payment_get_way ? orderDetail.payment_get_way  : 'NULL'}</td>
                                        <td className="">{orderDetail.payment_status ? orderDetail.payment_status  : 'NULL'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* END INVOICE */}
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          </div>
        </div>
      </section>



     
    </>
  );
};

export default OrderDetail;
