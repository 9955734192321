import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { useLocation , useNavigate} from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const transaction_Id = location?.state?.txnId || undefined;

  useEffect(() => {
    // if (transaction_Id == undefined) {
    //     navigate('/');
    // }
  }, []);

  useEffect(() => {
    localStorage.removeItem("order_id",);
    localStorage.removeItem("invoice_number",);
    localStorage.removeItem('billing_info');
    localStorage.removeItem('order_confirmation');
    localStorage.removeItem('terms_condition');
    localStorage.removeItem('selectedOrder');
    localStorage.removeItem('addMorePuppets');
  },[]);

  return (
    <>
      <section className="full_width common_heading">
        <div className="container">
          <div className="odr_frm">
            <FormLogo title="" />  
              <div className="of_bx_alfa">
                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <p>Payment Successfully Done</p>
                    {transaction_Id ? (
                      <p>Your Transaction ID : {transaction_Id}</p>
                    ) : (
                      <> </>
                    )}
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentSuccess;
