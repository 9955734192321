import React, { useState, useEffect } from 'react';
import FormLogo from '../elements/FormLogo';
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import ENDPOINT from "../../constants/api-endpoints";

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

// Initial form values
const initialValues = {
  email: "",
  password: "",
};

// Form component
const Login = () => {
  const navigate = useNavigate();
  const userLoginOrNot = localStorage.getItem("BearerToken");  
  useEffect(() => {
    if (userLoginOrNot != null) {
        navigate('/');
    }
  }, []);

  // Handle form submission 
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Assuming your API endpoint is "/api/submitForm"
      const response = await axios.post(ENDPOINT.LOGIN, values);
      console.log(response);
      if (response.data.status) {
        localStorage.setItem("BearerToken", response.data.token);
        localStorage.setItem("loginUserId", response.data.data.id);
        resetForm({});
        // Hire Talent Anonymously Redirect
        if(localStorage.getItem('hireTalentAnonymouslyState')) {
          const hireTalentAnonymouslyState = JSON.parse(localStorage.getItem('hireTalentAnonymouslyState'));
          localStorage.removeItem('hireTalentAnonymouslyState');
          navigate('/order-now',{state:{slug:hireTalentAnonymouslyState.slug, selectedImage:hireTalentAnonymouslyState.selectedImage}});
          navigate(0);
        } else {
          navigate(0);
          // window.location.href = ENDPOINT.APP_BASE_PATH + '/';
        }        
      } else {
        console.log("fail");
      }
    } catch (error) {
      // Handle errors
      console.error("Submission Error:", error);
      // Reset form after an error if needed
      setSubmitting(false);
    }
  };

  return (
    <>
      <section className="full_width common_heading">
        <div className="container">        
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <div className="odr_frm">
              <FormLogo title="Login" />  
              <Form>
                <div className="of_bx_alfa loginFrm">
                  { (localStorage.getItem('hireTalentAnonymouslyState')) ? (<h4 className="text-danger mb-4 text-center">Please login or signup with us to hire the talent.</h4>) : ("") }                    
                  <h4 className="mb-4 text-center">
                    Don't have an account? <Link to="/register">Sign up</Link> now!{" "}
                  </h4>
                  <div className="row">
                    <div className="col-sm-12">
                      {/* Email Field */}
                      <div className="d-flex mb-4 align-items-center justify-content-center">
                        <label for="email">Email:</label>
                        <Field type="text" id="email"  name="email" className="" placeholder="" />
                        <ErrorMessage className="text-danger"  name="email" component="div" />
                      </div>  

                      {/* Password Field */}
                      <div className="d-flex mb-4 align-items-center justify-content-center">
                        <label for="password">Password:</label>
                        <Field type="password" id="password"  name="password" className=""  placeholder="" />
                        <ErrorMessage className="text-danger" name="password" component="div" />
                      </div>

                      {/* Submit Button */}
                      <div className="d-flex mb-4 align-items-center justify-content-center">
                        <input type="submit" defaultValue="Login" value="Login" />
                      </div>

                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Formik>
        </div>
      </section>
    </>
  );
};

export default Login;
