import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import footer_logo from '../../css/images/footer_logo.png';
import ftr_caption from '../../css/images/ftr_caption.png';
import flw_us from '../../css/images/flw_us.png';
import fc_bk_icon from '../../css/images/fc_bk_icon.png';
import yt_icon from '../../css/images/yt_icon.png';
import insta_icon from '../../css/images/insta_icon.png';
import axios from 'axios';
import ENDPOINT from '../../constants/api-endpoints';

const Footer = () => {

  const [socialMediaLink, setSocialMediaLink] = useState({});

  useEffect(() => {
    axios.get(ENDPOINT.SOCIAL_MEDIA_LINK, {})
    .then((response) => {
       // Handle data
       setSocialMediaLink(response.data.data);
       
    })
    .catch((error) => {
      // console.log(error);
    })
  }, []);

  return (
    <>
      <footer className="full_width footer_main">
        <div className="footer_top">
          <div className="container-fluid">
            <div className="foot_logo">
              <Link to={'/'}>  
                <img src={footer_logo} alt="logo" />
              </Link>
            </div>
            <div className="row">
              <div className="col-sm-7 col-md-7 col-lg-7">
                <h2 className='footerLogoCaption'><img src={ftr_caption} alt="" /></h2>
              </div>
              <div className="col-sm-5 col-md-5 col-lg-5">
                <div className="foot_social">
                  <h2>
                    <img src={flw_us} alt="" />
                  </h2>
                  <ul>
                    <li>
                      <a href={ socialMediaLink.facebook } target="_blank">
                        <img src={fc_bk_icon} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href={ socialMediaLink.youtube } target="_blank">
                        <img src={yt_icon} alt="" />
                      </a>
                    </li>
                    <li>
                      <a href={ socialMediaLink.instgram } target="_blank">
                        <img src={insta_icon} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ftr_btm">
          <div className="container-fluid">
            <div className="row foot_nav text-center">
              <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about-us">About Us</Link></li>
                  <li><Link to="/puppet-profiles">PuppetGram Cast</Link></li>
                  <li> <Link to="/category">Backgrounds</Link></li>
                  <li><Link to="/order-now">Order Now</Link></li>
                  <li><Link to="/contact-us">Contact Us</Link></li>
              </ul>
            </div>
            <div className="copy_right">
              <ul>
                <li><a href="#">PuppetGram®</a></li>
                <li> <Link to="/terms-conditions">Terms & Conditions</Link></li>
                <li><a href="/pricing">Pricing</a></li>
                <li><a href="#">All Rights Reserved</a></li>
                <li><a href="#">Copyright {(new Date().getFullYear())}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
